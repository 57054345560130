@import "../variables";

.task-list {
  &__item {
    @extend .terry;
    margin: 0px 0px 0.8em;
    display: flex;
    align-items: start;

    &__toggle {
      cursor: pointer; }

    &__content {
      margin-left: 1em;
      margin-top: 2px;
      flex: 1; }

    &-crossed {
      text-decoration: line-through;
      color: transparentize(white, 0.6); } } }
