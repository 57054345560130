@import "../variables.sass";

.habits-section {
  padding-top: 20px;
  h2 {
    font-size: 32px;
    margin-bottom: 24px; } }

.habits {
  overflow: auto !important;

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
    grid-template-rows: 1fr;
    grid-gap: 1rem;
    justify-items: center;
    align-items: center;
    color: $offWhite;
    cursor: pointer;
    user-select: none;
    position: relative;
    width: 100%; }

  &__wrapper {
    padding: 0.5rem 0;
    width: 100%;
    box-sizing: border-box;
    font-size: 20px;
    height: 9em;

    &.menu-open {
 } }      // background: mix($appBlack, $offBlack)

  &__habit {
    position: relative;
    font-size: 20px;
    width: 8em;
    height: 8em;
    box-sizing: border-box;
    margin: 0 auto;

    .dials {
      position: absolute; }

    .checkmark {
      position: absolute;
      right: -8px;
      top: -4px; }

    .face {
      position: absolute;
      width: 72%;
      height: 100%;
      left: 14%;
      top: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      font-size: 12px;

      em {
        font-style: normal;
        color: $labelPurple; }

      .slug {
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 0.04em;
        font-weight: 600;
        min-height: 2em;
        line-height: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end; }

      .subject {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 17px;
        height: 2.8em;
        line-height: 1.1em;
        margin: 0.3em 0px 0.3em;

        h3 {
          margin: 0;
          color: white;
          font-size: 18px; } }

      span {
        color: transparentize(white, 0.6); }

      .stats {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;

        .stat {
          display: flex;
          flex-direction: column; }

        .sep {
          width: 4px;
          height: 2.4em;
          background: $offBlack;
          margin: 0px 0.6em; } } }

    .dials {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      position: absolute;
      transform: scaleX(-1); }

    .dial {
      border-radius: 50%;

      &.move {
        position: absolute;
        width: 8em;
        height: 8em;

        &::after {
          content: "";
          background: $appBlack;
          border-radius: 50%;
          width: 6.8em;
          height: 6.8em;
          position: absolute;
          top: 0.6em;
          left: 0.6em; }

        .dial-container {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 4em;
          overflow: hidden;

          .wedge {
            width: 8em;
            height: 8em;
            position: absolute;
            top: 0;
            left: 0; } }

        .container1 {
          left: 4em;
          transform-origin: 0% 50%;

          .wedge {
            border-radius: 8em;
            left: -4em;
            transform-origin: 50% 50%; } }

        .container2 {
          left: 0;
          transform-origin: 100% 50%;

          .wedge {
            border-radius: 8em;
            transform-origin: 50% 50%; } }

        .marker {
          width: 0.6em;
          height: 0.6em;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: calc(50% - 0.3em);

          &.end {
            transform-origin: 50% 4em; } }

        .dial-background {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 50%;

          &.one {
            background: $offBlack; } } } } } }
