$primaryBlue: #4B4BFF;
$primaryNavy: #072DAC;
$dangerColor: #E10F0F;
$bodyBlack: #23253D;
$bodySilver: #DCDDE5;
$buttonGreen : #0DBB8A;
$habitTrack : #EAE9EE;
$offWhite : #F3F3F3;
$offBlack : #1B1C30;
$labelPurple : #6F71FE;
$buttonMute : #9BA7BC;
$appBlack : #121322;
$appWhite : white;
$softWarning : #FF4C4C;

.h1 {
  font-size: 24px;
  font-weight: 700; }

.h2 {
  font-size: 22px;
  font-weight: 600; }

.h3 {
  font-size: 20px;
  font-weight: 700; }

.h4 {
  font-size: 18px;
  font-weight: 700; }

.terry {
  font-size: 17px;
  font-weight: 500; }

.large {
  font-size: 16px;
  font-weight: 600; }

.medium {
  font-size: 15px;
  font-weight: 500; }

.normal {
  font-size: 14px;
  font-weight: 500; }

.sub {
  font-size: 13px;
  font-weight: 500; }

.small {
  font-size: 12px;
  font-weight: 500; }

.tiny {
  font-size: 11px;
  font-weight: 500; }

.fine {
  font-size: 10px;
  font-weight: 500; }

.bare {
  font-size: 9px;
  font-weight: 500; }
