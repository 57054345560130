@import "../variables";

.mindsets {
  .mindset {
    background: $bodyBlack;
    margin: 0px 12px;
    background-size: cover;
    border-radius: 8px;
    padding-top: 55.55%;
    position: relative;
    cursor: pointer;

    .content {
      background: $bodyBlack;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 8px;
      font-size: 20px;

      h2 {
        text-align: center;
        margin: 0px 44px 24px;
        line-height: 1.2em; } } } }

.carousel .slide {
  background: transparent; }

.vision-board {
  margin: 1rem auto;
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(192px, 480px));
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0px 24px;

  .mindset {
    margin: 0px;

    .content {
      padding: 16px 32px;
      box-sizing: border-box; } } }
