.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  padding: 0px 24px;
  user-select: none;
  border-bottom: 0.5px solid transparentize(white, 0.9);

  .logo {
    font-weight: 700;

    .beta {
      color: black;
      background: transparentize(white, 0.6);
      border-radius: 3px;
      padding: 4px 8px;
      font-size: 10px;
      vertical-align: top;
      margin-left: 12px;
      display: inline-block; } }

  &__nav {
    display: flex; }

  &__item {
    padding: 0px 8px;
    cursor: pointer;

    img {
      width: 32px;
      height: 32px; }

    &--active {
      img {} } }

  &__profile-box {
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 6px 6px 6px 10px;
    background: transparentize(white, 1);
    transition: 0.5s background;
    cursor: pointer;

    &:hover {
      background: transparentize(white, 0.8);
      transition: 0.5s background; }

    .avatar {
      height: 36px;
      img {
        width: 36px;
        height: 36px;
        border-radius: 18px; } } } }
