@import "../variables.sass";

.dashboard {
  padding: 0px 24px 48px;
  user-select: none;
  display: flex;
  flex: 1;

  h2 {
    margin: 0em 0em 0.4em;
    font-size: 32px; } }
