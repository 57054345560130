@import "variables.sass";

body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  margin: 0 auto;
  background: $appBlack;
  color: $offWhite;
  height: 100vh;
  font-weight: 600; }

a {
  color: white;
  text-decoration: none; }

*, *:focus {
  outline: 0; }


h1, h2, h3, h4, h5, h6 {
  margin: 0px; }

[tabindex="-1"] {
  flex: 1;
  display: flex;
  flex-direction: column; }

.MuiListItemIcon-root {
  min-width: 36px !important; }

.App {
  display: flex;
  flex-direction: column;
  margin: 0px;
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;

  &-menu {
    height: 64px; }

  &-body {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column; }

  .wrapper {
    margin: 0px auto;
    max-width: 960px;
    width: 100%;
    box-sizing: border-box; }

  .spacer {
    flex: 1; }

  .hsep {
    width: 12px; }

  .vsep {
    height: 12px; }

  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin); }

  .App-footer {
    display: flex;
    height: 64px; } }
