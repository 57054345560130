@import "../variables.sass";

.goals-section {
  padding-top: 20px;
  h2 {
    font-size: 32px;
    margin-bottom: 24px; } }

.goals__list {
  position: relative; }

.goal {
  position: relative;
  color: $offWhite;
  background-color: $offBlack;
  border-radius: 8px;
  height: 108px;
  line-height: 108px;
  vertical-align: middle;
  box-sizing: border-box;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  margin: 0px 0px 16px;

  &__base {
    box-sizing: border-box;
    height: 108px;
    position: absolute;
    width: 100%;

    .content {
      padding: 0px 24px;
      display: flex;
      justify-content: space-between;
      font-weight: 700;

      .subject {
        font-size: 17px;
        opacity: 0.4; } } }

  &__top-content {
    position: absolute;
    color: $offWhite;
    overflow: hidden;
    box-sizing: border-box;
    white-space: nowrap;

    .content {
      box-sizing: border-box;
      padding: 0px 24px;
      display: flex;
      justify-content: space-between;
      font-weight: 700;

      .subject {
        font-size: 17px;
        padding: 0;
        margin: 0;
        font-weight: 700; } } }

  &__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    svg {
      position: absolute;
      width: 100%;
      left: 0%;
      top: -50%;
      transform: translateY(50%); } } }
