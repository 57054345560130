@import "../variables";

.priorities-section {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  h2 {
    font-size: 32px; } }

.priorities-active {
  background: $offBlack;
  padding: 2.4em;
  min-width: 400px;
  max-width: 640px;
  min-height: 320px;

  &.MuiPaper-rounded {
    border-radius: 8px; } }
